import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import { IConfig } from './config.models';

@Component({
    selector: 'ngx-dropdown-menu-search',
    template: `<div class="ht0 dropdown relative" (window:mouseup)="autoCloseDropdown($event)">
	<div [ngStyle]="heightAndLineHeight" *ngIf="!config?.searchEnabled || (config?.searchEnabled && !optionsVisible)" [ngClass]="{'disabled' : config?.isDisabled, 'cursorClass' : !config?.isDisabled, 'gray-border-darker' : !errorVisible, 'red-border-light' : errorVisible}"  (click)="dropdownClick()" class="dropdown-label setBorder border-radius in-block setPadding">
		<span class="in-block setMargin ellipsis">{{selectedOption}}</span>
		<span [style.line-height]="config?.lineHeight" class="chevron-down-icon ft-size18"></span>
	</div>

	<input [ngStyle]="heightAndLineHeight" [(ngModel)]="searchTerm" *ngIf="optionsVisible && config?.searchEnabled" (click)="optionsVisible = true" class="dropdown setPadding setWidth100 border-radius gray-border-darker setBorder" type="text" placeholder="Search">

	<div *ngIf="optionsVisible" style="width: 100%" [ngClass]="{'bottom0' : config?.flow === 'up'}" class="setMaxHeight scrollable mgn-bottom text-left shadow absolute z white-bg setZ border mgn-bottom50 gray-border-darker border-radius setPaddingTopBottom" id="{{config?.scrollbarVisible ? 'scrollbar-style' : null}}">
		<ul *ngFor="let option of options | searchFilter:searchTerm; let i = index">
			<li [ngClass]="{'gray-bg' : option.name === element?.id}" (mouseup)="onOptionClick(option, option.name)" id="{{option.name}}"  class="option cursorClass">{{option.name}}<i *ngIf="option.name === selectedOption" class="fa fa-check mgn-left10" aria-hidden="true"></i></li>
		</ul>
	</div>
	<input *ngIf="optionsVisible" #hiddenSearchInput type="text" class="hidden-search" (keyup)="onInputChange($event, hiddenSearchInput.value)">
</div>`,
    styles: [`input:focus{outline:0}.dropdown-label{background-color:#fff;width:100%;position:relative}.bottom0{bottom:0}.chevron-down-icon{display:inline-block;width:8px;height:8px;border-bottom:1px solid #98999a;border-right:1px solid #98999a;position:absolute;right:.625em;top:50%;-webkit-transform:translateY(-50%) rotate(45deg);transform:translateY(-50%) rotate(45deg)}.gray-border-darker{border-color:#bbc0c3}.cursorClass{cursor:pointer}.hidden-search{position:absolute;width:50%;left:0;top:35px}.ft-size18{font-size:1.125rem}.absolute{position:absolute}.setPaddingTopBottom{padding-top:.063rem;padding-bottom:.063rem}.right10{right:.625rem;top:.5rem}.ellipsis{width:90%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.setPadding{padding-left:.5rem;padding-right:.5rem}.shadow{box-shadow:0 .125em .313em 0 rgba(0,0,0,.16),0 .125em .625em 0 rgba(0,0,0,.12)}.z{z-index:99}.white-bg{background-color:#fff}.setMargin{margin:0}.scrollable{overflow-y:scroll;overflow-x:hidden;-webkit-overflow-scrolling:touch}#scrollbar-style::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);border-radius:10px;background-color:#f6f8f8}#scrollbar-style::-webkit-scrollbar{width:5px;background-color:#f6f8f8}#scrollbar-style::-webkit-scrollbar-thumb{border-radius:10px;-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);background-color:#d6d9db}.mgn-bottom50{margin-bottom:3.125rem}.text-left{text-align:left}.setMaxHeight{max-height:14rem}.setLeft90{left:90%}.setWidth100{width:100%}.mgn-left10{margin-left:.625rem}.relative{position:relative}.border-radius{border-radius:.25em}.in-block{display:inline-block}.setPaddingLeft{padding-left:60px!important}.setPaddingLeft26{padding-left:26px!important}.option:hover{background-color:#e6e8e9}.setWidth50{width:50%}ul{padding-left:0;margin:0}ul li{padding-left:.5rem;padding-top:.2rem;padding-bottom:.2rem}.setBorder{border-width:1px;border-style:solid}.setZ{z-index:300}`]
})
export class NgxDropdownMenuSearchComponent implements OnInit {

    @Input() errorVisible: boolean;
    @Input() options: any[];
    @Input() selectedOption: any;
    @Input() config: IConfig;

    @Output() onDropdownClick: EventEmitter < any > = new EventEmitter < any > ();
    @Output() onOptionSelect: EventEmitter < any > = new EventEmitter < any > ();

    @ViewChild('hiddenSearchInput') hiddenSearchInput: ElementRef;

    public element: any;
    public heightAndLineHeight: any;
    public optionsVisible: boolean;
    public searchTerm: string;


    constructor() {
    }

    ngOnInit() {
        this.heightAndLineHeight = {
            'height': this.config ? this.config.height : '20px',
            'line-height': this.config ? this.config.lineHeight : '20px'
        }
    }

    public dropdownClick(): void {
        if (this.config && !this.config.isDisabled) {
            this.optionsVisible = true;
            this.onDropdownClick.emit();
            //automatically select hidden input so we can quickly find an item
            setTimeout( () => this.hiddenSearchInput.nativeElement.select(), 10); 
        } 
    }

    public onInputChange(event, term: string): void {
      let lowerCaseTerm = term.toLocaleLowerCase();
      let foundOption =  this.options.find( (option) => {
            if(option.name) {
              return this.includes(option.name.toLocaleLowerCase(), lowerCaseTerm);  
            }           
        });
      this.scrollToFoundOption(foundOption);     
      this.selectOptionOnEnterClick(event, foundOption);
    }

    public onOptionClick(option: any, name: any): void {
        this.selectedOption = name;
        this.optionsVisible = false;
        this.onOptionSelect.emit(option);
    }

    public autoCloseDropdown(event) {
        if (!event.target.closest(".dropdown")) {
            this.optionsVisible = false;
        }
    }

    private includes(container: any, value: string): boolean {
      if(container) {
        let includesValue: boolean;
        let index: number = container.indexOf(value);
        if(index >= 0) {
            includesValue = true;
        }
        return includesValue;
      }      
    }

    private scrollToFoundOption(foundOption): void {
        if(foundOption) {
            this.element = document.getElementById(foundOption.name);
            this.element.scrollIntoView();          
        }
    }

    private selectOptionOnEnterClick(event, foundOption): void {
        if(event.keyCode === 13) {
            this.optionsVisible = false;
            this.onOptionClick(foundOption, this.element.id);
        }
    }


}
